@import "assets/scss/variables.scss";

.manageDashboard-dialog-container {
  .paper-container {
    max-width: 400px;
  }
  .mui-field-container {
    margin-bottom: 4px;
  }
  .max-length-area {
    color: $grey-300;
    text-align: right;
    font-family: "Rubik-Regular";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .loading-icon {
    animation: rotate 3s linear infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-message-below-input {
  width: 100%;
  font-size: 12px;
  color: $red-500;
  font-weight: 500;
  line-height: 1.5;
}
