@import "assets/scss/variables.scss";

.table-pag-container {
  display: flex;
  align-items: center;
  margin-top: 24px;
  width: 100%;

  .pag-position {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .position-center {
    justify-content: center;
  }
  .position-left {
    justify-content: flex-start;
  }
  .position-right {
    justify-content: flex-end;
  }

  .pag-title {
    font-size: 14px;
    font-weight: 400;
    color: $grey-1;
    margin-right: 16px;
  }

  .mui-outlinedInput-override {
    margin-bottom: 0;
    width: 50px;
    height: 32px;
    margin-right: 32px;
  }

  .pagination-wrapper {
    display: flex;
    justify-content: center;

    .MuiPaginationItem-page {
      border: 1px solid var(--neutral-400);
      background: var(--white);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: var(--blue-900);
    }

    .Mui-selected {
      background-color: #fff;
      border: 1px solid $brand-primary;
      color: $brand-primary;
    }

    .MuiPaginationItem-ellipsis {
      user-select: none;
    }
  }
  .mui-field-container {
    margin: 0;
  }
}
