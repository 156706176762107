@import "assets/scss/variables.scss";

.detailed-company-profile {
  border-radius: 4px;
  background: #fff;
  padding: 24px;
  box-shadow: 0px 0px 16px 0px rgba(137, 151, 164, 0.1);

  .company-block {
    max-width: 100%;
    box-sizing: border-box;
  }

  .company-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    h2 {
      font-family: "Rubik-Regular";
    }
  }

  .data-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    .block-title {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; /* 150% */
      color: #0d213f;

      .edit-company-data {
        margin-left: 4px;
      }
    }

    .category-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }

    .category-desc {
      color: #818e9c;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .category-value {
      font-family: "Rubik-Regular";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $gray-700;
      margin-left: 8px;
    }
  }
}
