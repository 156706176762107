@import "assets/scss/variables.scss";

.user-management {
  display: flex;
  flex-direction: column;
  flex: 1;

  .users-content {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    height: 100%;
  }

  .search-wrapper {
    display: grid;
    grid-template-columns: 251px 156px;
    gap: 24px;
    justify-content: end;
    margin-bottom: 16px;
    margin-left: auto;

    .mui-outlinedInput-override {
      margin-bottom: 0px;
    }
  }
}
