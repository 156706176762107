@import "assets/scss/variables.scss";

.admin-management {
  width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;

  .page-container {
    padding: 0px 56px 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .admin-users-content {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    height: 100%;
  }

  .search-wrapper {
    display: grid;
    grid-template-columns: 251px 156px;
    gap: 24px;
    justify-content: end;
    margin-bottom: 16px;
    margin-left: auto;

    .mui-outlinedInput-override {
      margin-bottom: 0px;
    }
  }
}
