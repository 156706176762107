.fixed-block {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .widgets-list {
    width: 500px;
    height: 200px;
    border-radius: 8px;
    background-color: #0d213f;
  }

  .close-widgets-list {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0d213f;
    margin-top: 8px;
  }
}
