.sorting-container {
  .MuiPaper-root {
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2);
  }

  .MuiMenuItem-root {
    height: 40px;
    min-height: 40px;
    box-sizing: border-box;
  }

  .category-title {
    height: 40px;
    padding: 0 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #868e97;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .sort-icon {
    margin-right: 8px;
  }

  .active-sorting {
    font-weight: bold;
  }
}

.isSortingEmpty {
  opacity: 0.2;
}
