.add-widget-wrapper {
  position: relative;

  .add_widget_btn {
    width: 100%;
    min-height: 56px;
    height: 100%;
    border-radius: 4px;
    border: 1px dashed #4e678a;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.options-menu {
  border-radius: 8px;
  background-color: #0d213f;
  li {
    font-family: "Rubik-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #e8f1f4;
    display: flex;
    align-items: center;
    img {
      margin-right: 6px;
    }
  }
}
