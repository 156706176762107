@import "assets/scss/variables.scss";

.client-mode-switcher {
  width: 100%;
  height: 56px;
  background-color: $secondary-500;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 11;

  .title {
    display: inline-flex;
    * {
      font-family: "Rubik-Semibold";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .title-right,
    .comp-name {
      margin-left: 4px;
    }
    .title-left {
      margin-right: 4px;
    }

    .title-link {
      display: flex;
      align-items: center;
      color: $intuilize-primary-main;
    }

    img {
      margin: 0 4px;
    }
  }

  .actions {
    .btns-group {
      display: flex;
      gap: 4px;
    }
  }
}
