.client-content {
  height: 100vh;
  display: flex;
}

.dashboard-side {
  width: 100%;
  margin-left: 256px;
  background-color: #e8f1f4;
}

.dashboard-side-with-margin {
  margin-top: 56px;
}

.editable-client-content {
  height: 100vh;
}
