.skeleton-container {
  width: 100%;
  margin-top: 15px;
  padding: 0 20px;

  .skeleton-header {
    width: 100%;
    height: 70px;
  }

  .skeleton-content {
    display: flex;
    margin-top: 15px;
  }

  .skeleton-left-block,
  .skeleton-right-block {
    width: 50%;
    height: 400px;
  }
  .skeleton-left-block {
    padding-right: 15px;
  }
  .skeleton-right-block {
    padding-left: 15px;
  }

  .skeleton-footer {
    width: 100%;
    height: 400px;
    margin-top: 15px;
  }
}
