@import "assets/scss/variables.scss";

.dashboard-content {
  padding: 16px 48px;
  background-color: #e8f1f4;
}

.widget_base-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  .dashboard-title {
    font-family: "Rubik-Semibold";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.64px;
    color: $neutral-900;
    min-height: 38px;
  }
  .dashboard-actions {
    display: flex;
    gap: 4px;
  }
}

.helico-data {
  padding: 16px 48px;
  background-color: #e8f1f4;
  margin-bottom: -2px;
  position: relative;
  padding-left: 32px;
}
