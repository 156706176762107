@import "assets/scss/variables.scss";

.admin-header {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #fff;
  z-index: 1;
  padding: 0px 56px;

  .header-tabs-wrapper {
    margin-left: 32px;
  }

  .company-dark-logo {
    cursor: pointer;
  }

  .naw-wrapper {
    display: flex;
    align-items: center;
  }

  .header-info {
    max-width: 50vw;
    display: flex;

    .adminName {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $gray-700;
    }

    .adminRole {
      font-size: 11px;
      font-weight: 400;
      line-height: 150%;
      color: $gray-200;
    }

    .logout-icon {
      cursor: pointer;
      margin-left: 16px;
    }
  }
}
