@import "assets/scss/variables.scss";

.addDashboard-dialog-container {
  .paper-container {
    max-width: 400px;
  }
  .mui-field-container {
    margin-bottom: 4px;
  }
  .max-length-area {
    color: $grey-300;
    text-align: right;
    font-family: "Rubik-Regular";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .checkbox-area {
    label {
      display: flex;
      align-items: center;
      margin-left: -10px;
    }
  }
}
