.chart-wrapper-container > :first-child {
  padding-right: 8px;
  padding-bottom: 8px;
}

.chart-wrapper-container > :nth-child(2) {
  padding-left: 8px;
  padding-bottom: 8px;
}

.chart-wrapper-container {
  display: flex;
  width: 100%;
  gap: 10px;
}
