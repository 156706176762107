@import "assets/scss/variables.scss";

.logo-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;

  .logo-area {
    width: 65px;
    height: 64px;
    background-color: #f0f9ff;
    margin-right: 12px;
    border-radius: 50%;
  }

  .action {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #27aae1;
  }
  .logo-type {
    color: #1a1a36;
    font-family: Rubik-Regular;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    background-color: #f8fbfc;
  }
}
