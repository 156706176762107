@import "assets/scss/variables.scss";

.select-wrapper {
  position: relative;
  .MuiSelect-icon {
    display: none !important;
  }

  .custom-select {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: $brand-primary !important;
      }
    }
  }

  .select-open {
    transform: rotate(180deg);
  }

  .arrow-select {
    margin-right: -13px;
  }

  .zero-padding {
    .MuiSelect-select {
      padding-right: 0 !important;
    }
  }
}

.custom-select-dropdown {
  .item-title .MuiTypography-root {
    font-family: "Rubik-Regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  &.is-selected {
    background-color: #ebf1f5;
    .item-title .MuiTypography-root {
      font-family: "Rubik-Medium";
      font-weight: 500;
    }
  }
}
