@import "assets/scss/variables.scss";

.recovery-page {
  max-width: 440px;
  width: 100%;

  .recovery-subtext {
    font-size: 14px;
    text-align: center;
  }

  .box-actions {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
