@import "assets/scss/variables.scss";

.navigation-tabs {
  display: flex;
  align-items: center;
  height: 40px;
  .nav-list {
    display: flex;

    .nav-item {
      margin-right: 32px;
      color: #818e9c;
      position: relative;
      a {
        font-family: Poppins-Medium;
      }
    }

    .item-link {
      position: relative;

      &.iaActive {
        color: #1a1a36;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -17px;
          width: 100%;
          height: 4px;
          background-color: #239bcd;
          transition: width 0.3s, left 0.3s;
        }
      }
    }
  }

  .item-link {
    position: relative;

    &.iaActive {
      color: #1a1a36;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -17px;
        width: 100%;
        height: 4px;
        background-color: $brand-primary;
        transition: width 0.3s, left 0.3s;
      }
    }
  }
}
