body {
  margin: 0; 
	background-color: var(--neutral-100);
  font-family: "Rubik-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code { 
  font-family: "Rubik-Regular", sans-serif;
}

#root {
  min-height: 100vh;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
:before,
:after,
button,
input,
select,
textarea,
hr {
	margin: 0;
	padding: 0;  
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box; 
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  font-family: "Rubik-Regular", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik-Medium", sans-serif;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

* {
	outline: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

a {
	text-decoration: none;
}

a:visited,
a:link {
	color: inherit;
}

textarea {
	width: 100%;
	resize: none;
	outline: none;
} 

::-webkit-scrollbar {
	width: 8px;
	height: 6px;
}

::-webkit-scrollbar-track {
	background: var(--neutral-100);
	border-radius: 12px;
}

::-webkit-scrollbar-thumb {
	background: var(--neutral-400);
	border-radius: 4px;
}

.fz-10 {
	font-size: 10px;
}

.fz-12 {
	font-size: 12px;
}

.fz-14 {
	font-size: 14px;
}

.fz-16 {
	font-size: 16px;
} 

.Rubik-Medium-font {
  font-family: "Rubik-Medium", sans-serif;
} 

.mui-override-hover:hover .MuiOutlinedInput-notchedOutline {
      border-color: #27aae1 !important;
}

.bold {
  font-weight: bold;
}

.textTransform-lowerCase {
	text-transform: lowercase;
}

.main-text-color {
	color: var(--body-text);
}

.text-error-color {
	color: #e05a5c !important;
}

.bg-red {
	background-color: #FFDDD6;
	color: #1a1a36;
}

.bg-success {
	color: #fff;
	background-color: #00A6A9;
}

.bg-neutral-100 {
	color: #1a1a36;
	background-color: var(--neutral-100);
}

.neutral-400 {
	color: var(--neutral-400);
}

.neutral-500 {
	color: var(--neutral-500);
} 
 
.neutral-300 {
	color: var(--neutral-300) !important;
}

.mt-4 {
	margin-top: 4px;
}

.mt-8 {
	margin-top: 8px;
}
.mt-12 {
	margin-top: 12px;
}

.mb-12 {
	margin-bottom: 12px;
}

.mb-16 {
	margin-bottom: 16px;
}

.default-cursor {
	cursor: auto !important;
}

.d-flex{
	display: flex;
} 

.h-center-content {
	align-items: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus { 
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-moz-autofill {
  box-shadow: 0 0 0px 1000px white inset;
  -moz-box-shadow: 0 0 0px 1000px white inset;
  border: 1px solid #ddd;
}