@font-face {
  font-family: "Rubik-Medium";
  src: url("../fonts/Rubik-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Regular";
  src: url("../fonts/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Semibold";
  src: url("../fonts/Rubik-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins-Regular.ttf") format("truetype");
}
