@import "assets/scss/variables.scss";

.companies-table {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  .table-wrapper {
    overflow-x: auto;
    background-color: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  }

  .table-header {
    .header-cell {
      color: #818e9c;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .table-row {
    display: grid !important;
    grid-template-columns:
      minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(
        200px,
        1fr
      )
      minmax(175px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) 70px !important;

    .table-cell,
    .header-cell {
      padding: 0 16px;
      height: 48px;
      border-right: 1px solid white;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .table-cell {
      font-size: 14px;
      font-family: "Rubik-Regular";
    }
  }

  .company-data-cell {
    display: flex;
    align-items: center;
    max-width: calc(100% - 32px);
    cursor: pointer;

    .company-info {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      margin-left: 16px;

      .company-name {
        color: $gray-700;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Rubik-Medium;
      }

      .company-industry {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: var(--neutral-500);
        font-family: Rubik-Regular;
      }
    }
  }
}

.mui-menu {
  .MuiPaper-root {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05) !important;
  }
}
