@import "assets/scss/variables.scss";

.page-empty-state {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  .title {
    color: $gray-700;
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
}
