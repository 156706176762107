@import "assets/scss/variables.scss";

.datepicker-wrapper {
  .MuiStack-root {
    width: 100% !important;
  }

  .MuiInputBase-root {
    height: 40px !important;
  }

  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: $brand-primary !important;
    }
  }
}

.popper-datepicker {
  .MuiPickersDay-today {
    border: 1px solid $brand-primary !important;
  }

  .Mui-selected {
    color: #fff !important;
    background-color: $brand-primary !important;
    border: none !important;
  }
}

.MuiPickersFadeTransitionGroup-root {
  * {
    font-family: "Rubik-Regular" !important;
  }
}
