@import "assets/scss/variables.scss";

.table-empty-state {
  max-width: 400px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto;

  .logo {
    animation: moveUpDown 2s infinite;
  }

  .title {
    color: $gray-700;
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .subtitle {
    text-align: center;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $gray-700;
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
