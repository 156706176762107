@import "assets/scss/variables.scss";

.dialog-title-wrapper {
  padding: 24px;
  padding-bottom: 8px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: $gray-700;
  font-family: "Rubik-Medium";
}
