.status-container {
  .status-cell {
    display: flex;
    height: 24px;
    padding: 4px 16px;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #000;
    color: #000;
    text-transform: capitalize;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    position: relative;
    width: 105px;

    .is-open {
      transform: rotate(180deg);
    }
  }

  .disabled {
    user-select: none;
    pointer-events: none;
  }

  .status-active {
    border: 1px solid #15b300 !important;
    color: #15b300 !important;
  }

  .status-inactive {
    border: 1px solid #f00 !important;
    color: #f00 !important;
  }

  .status-invited {
    border: 1px solid #ff9900 !important;
    color: #ff9900 !important;
  }

  .status-blocked {
    border: 1px solid red !important;
    color: red !important;
  }

  .status-disabled {
    border: 1px solid gray !important;
    color: gray !important;
    opacity: 0.5;
    cursor: not-allowed;
    user-select: none;
  }

  #status-btn {
    .MuiButton-text {
      font-size: 12px;
    }
  }
}
