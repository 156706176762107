@import "assets/scss/variables.scss";

.add-on-value {
  display: flex;
  align-items: center;
  font-family: "Rubik-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $gray-700;
  margin-left: 8px;

  .circle-counter {
    margin-left: 4px;
    min-width: 24px;
    width: 24px;
    min-height: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #239bcd;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: "Rubik-Regular";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #fff;
  }
}
