@import "assets/scss/variables.scss";

.mui-field-container {
  margin-bottom: 16px;

  .outlinedInput-error-message {
    margin-top: 4px;
    font-size: 12px;
  }
}

.mui-outlinedInput-override {
  font-size: 14px !important;
  height: 40px;
  background: #fff;
  input {
    height: 40px;
    padding: 0px 12px;
  }

  .endAdornment-icon {
    cursor: pointer;
    margin-left: 8px;
  }
  .endAdornment-icon:first-child {
    margin-left: 0;
  }

  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: $brand-primary !important;
    }
  }
}

input {
  font-size: 14px !important;
}

.outlined-label {
  font-size: 14px;

  .label-star {
    color: $red-E500;
    margin-left: 2px;
  }
}

.endAdornment-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
