.controlled-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 133%;
  -webkit-transform: translate(0, -1.5px) scale(0.75);
  -moz-transform: translate(0, -1.5px) scale(0.75);
  -ms-transform: translate(0, -1.5px) scale(0.75);
}
