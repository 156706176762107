.subheader-control {
  position: relative;
  height: 54px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 56px;
  margin-bottom: 26px;
  background-color: #fff;
  box-shadow: 0 0 12px #0000001a;

  .subheader-title {
    color: var(--blue-900);
    font-family: Poppins-Medium;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
}
