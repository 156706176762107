@import "assets/scss/variables.scss";

.sign-in-wrapper {
  max-width: 440px;
  width: 100%;

  .login-form {
    margin-top: 24px;
  }

  .recovery-block {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    .recovery-link {
      color: $button-link;
      font-weight: 500;
      font-size: 14px;
      font-style: normal;
      text-align: right;
      font-feature-settings: "clig" off, "liga" off;
      line-height: 18px;
    }
  }

  .submit-button {
    margin-top: 16px;
  }

  .outlined-input-error- .MuiOutlinedInput-notchedOutline {
    border-color: $red-300 !important;
  }
}
