.set-password-page {
  max-width: 440px;
  width: 100%;

  .validation-rules {
    margin-bottom: 16px;
  }

  .validation-rule {
    display: flex;
    align-items: center;
    color: #868e97;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;

    img {
      margin-right: 4px;
    }
  }

  .valid-by {
    color: #127307;
  }
}
