@import "assets/scss/variables.scss";

.cropper-dialog {
  .MuiPaper-root {
    max-width: 600px;
    width: 100%;
  }

  .crop-action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .crop-actions {
      display: flex;
      gap: 8px;
    }
  }
  .crop-logo-preview {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }

  .crop-container {
    width: 100%;
    height: 300px;
    position: relative;
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }

  .disable-crop {
    pointer-events: none;
    opacity: 0.5;
  }

  .controls {
    display: flex;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 16px;
  }

  .mui-slider {
    color: $gray-700;
  }

  .dragArea-title {
    font-family: "Rubik-Regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #818e9c;
    margin-top: 16px;
  } 

  .dragable-area {
    border-radius: 4px;
    border: 1px dashed #c9d1d9;
    background: #fff;
    padding: 24px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .description {
      max-width: 300px;
      margin-top: 4px;
      text-align: center;
      font-family: "Rubik-Regular";
      font-size: 14px;
      line-height: 160%;
      color: rgba(26, 26, 54, 0.75);
      .clickToUpload {
        color: #27aae1;
      }
    }
  }
}
