@import "assets/scss/variables.scss";

.check-connection {
  border-radius: 8px;
  border: 1px solid #c9d1d9;
  background: #fff;
  padding: 16px;

  .subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .checking-area {
    padding: 12px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-radius: 4px;

    .rotate-animation {
      animation: rotate 2s linear infinite;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
