$min-view-width: 320px;
$mobile-width: 480px;
$tablet-width: 1024px;

//colors
$brand-primary: #27aae1;
$intuilize-primary-main: #156de1;
$white: #fff;

$secondary-500: #dde44d;

$red-100: #fde9df;
$red-300: #ff4d3d;
$red-500: #cc0f00;
$red-E500: #e05a5c;

$grey-1: #7a7c7e;
$grey-100: #e8f1f4;
$grey-300: #4e678a;

$gray-200: #7b7171;
$grey-500: #123658;
$gray-700: #0d213f;

$neutral-300: #4e678a;
$neutral-N400: #c9d1d9;
$neutral-900: #1a1a36;

$button-link: #004bad;

:root {
  --white: #fff;
  --body-text: #1d252d;

  --neutral-100: #f8fbfc;
  --neutral-300: #4e678a;
  --neutral-400: #cacaca;
  --neutral-500: #818e9c;

  --blue-900: #031529;
}
