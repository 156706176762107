@import "assets/scss/variables.scss";

.auth-container {
  width: 100vw;
  min-height: 100vh;
  background-color: #1a1a36;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px;

  .white-block {
    width: 100%;
    max-width: 480px;
    background-color: #fff;
    padding: 32px 24px;
    position: relative;
    border-radius: 6px;
  }

  .auth-logo {
    color: #fff;
    position: absolute;
    top: -74px;
    left: 50%;
    transform: translate(-50%);
  }

  .auth-title {
    font-style: normal;
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
  }
}

.error-message-block {
  padding: 12px;
  font-size: 14px;
  font-style: normal;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 400;
  line-height: 24px;
  background-color: $red-100;
  color: #223a49;
  border-radius: 4px;
}
