.detailed-page-container {
  min-height: calc(100vh - 126px);
  padding: 0 56px;
  display: flex;
  flex-direction: column;

  .data-wrapper {
    margin-top: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }
}
