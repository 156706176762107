@import "assets/scss/variables.scss";

.mui-button-override {
  color: $white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 40px;
  line-height: 24px;
  font-family: "Rubik-Medium";

  &.MuiButton-outlined {
    color: $brand-primary;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    border: 1px solid $brand-primary;
  }
  &.MuiButton-text {
    color: $brand-primary;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
  }

  &.MuiButton-textError {
    color: $red-500;
  }

  &.MuiButton-outlined.mui-disabled-btn {
    background-color: var(--neutral-100);
    color: #c9d1d9;
    border: 1px solid var(--neutral-100);
  }

  &.mui-disabled-btn {
    color: $white;
    background-color: $neutral-N400;
  }
}
