@import "assets/scss/variables.scss";

.manageCompany-dialog-container {
  .paper-container {
    max-width: 400px;
    width: 100%;
  }

  .hidden-step {
    display: none;
  }
}
